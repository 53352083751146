import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-4 col-12 order-lg-1'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-one.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-12 order-lg-0'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Self-Hosted Version – Maximum Control with Cloud Benefits</h2>
                                <p>
                                    For organizations that need greater control and customization over their environment, the Self-Hosted Storage Gateway provides the flexibility to manage your own instance
                                    within your existing cloud account. Available through major cloud marketplaces like AWS, Azure, and Google Cloud, this version enables you to deploy, configure, and manage
                                    the solution according to your specific needs.
                                </p>
                                <p>

                                    With the Self-Hosted version, you retain full control over your storage gateway's configuration, scaling, and maintenance. You can fine-tune performance, adjust storage
                                    settings, and integrate the solution seamlessly with your existing cloud infrastructure or on-premises systems. This flexibility is particularly valuable for businesses
                                    with complex or highly specialized IT environments, where custom configurations and integrations are required to meet unique operational workflows.
                                </p>
                                <p>
                                    Although you manage the infrastructure, you still benefit from the scalability and reliability of the cloud. The Self-Hosted version offers the ability to scale resources
                                    up or down based on demand, while leveraging the inherent elasticity of cloud environments to handle growing data volumes or fluctuating workloads. While you’re responsible
                                    for maintenance, our support team is always available to provide assistance when needed, ensuring you’re never left to manage it alone.
                                </p>
                                <p>
                                    The Self-Hosted version also allows you to optimize costs by only paying for the resources you use, and provides a higher degree of flexibility for businesses that require
                                    advanced configurations, specialized security protocols, or custom workflows. It’s the ideal choice for enterprises or advanced users who need to balance control with the
                                    benefits of cloud-based performance.

                                </p>


                            </div>
                        </div>

                    </div>
                </div>
            </section >
        </>
    );
};

export default FeatureImg;
