import React from 'react';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureOne from '../components/features/FeatureOne';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import HeroEight from './HeroEight';
import CtaFive from '../components/cta/CtaFive';
import Pricing from '../components/pricing/Pricing';
import Faq from '../components/faq/FaqTwo';

const PricingApp = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Managed SFTP File Transfer' />
      <Navbar navDark/>
       <HeroEight />
      
        <Faq />

      <FooterOne footerLight />
    </Layout>
  );
};

export default PricingApp;
