import React from 'react';

const FeatureImgContentSix = () => {
    return (
        <>
            <section className='app-two-feature-three bg-light ptb-120'>
                <div className='container'>
                    <div className='row align-items-center justify-content-lg-between'>
                        <div className='col-lg-5 col-xl-5 col-sm-12'>
                            <div className='app-two-feature-three-left'>
                                <div className='app-content-feature-wrap'>
                                    <h2>On-premise solution</h2>
                                    <p>
                                        We understand that every organization has unique storage needs, which is why we offer both managed and self-hosted versions of our Storage Gateway. Whether you need a
                                        fully managed service or prefer greater control over your infrastructure, our solution gives you the flexibility to choose the deployment model that best suits your
                                        business and IT strategy.
                                    </p>
                                </div>
                                <div
                                    className='accordion faq-accordion mt-5'
                                    id='accordionExample'
                                >
                                    <div className='accordion-item active border-0'>
                                        <h5 className='accordion-header' id='faq-1'>
                                            <button
                                                className='accordion-button'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-1'
                                                aria-expanded='true'
                                            >
                                                <i className='far fa-bell pe-3'></i> As managed service
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-1'
                                            className='accordioncollapse collapse show'
                                            aria-labelledby='faq-1'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                For organizations that want a hassle-free, fully supported experience, our Managed Storage Gateway is available as a SaaS (Software-as-a-Service)
                                                solution. With this version, you get a fully managed service that includes expert support, automatic updates, and built-in scalability, all backed by our expert team. Let
                                                us handle the infrastructure, so you can focus on driving your business forward.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='accordion-item border-0'>
                                        <h5 className='accordion-header' id='faq-2'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-2'
                                                aria-expanded='false'
                                            >
                                                <i className='far fa-command pe-3'></i>
                                                Self-Hosted Version
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-2'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='faq-2'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                For businesses that require more control over their environment, the Self-Hosted Storage Gateway provides the flexibility to deploy and manage the
                                                solution within your own cloud account. Available through major cloud marketplaces, this option gives you full control over the configuration, scaling, and maintenance of
                                                your system, while still benefiting from the performance, reliability, and scalability of cloud infrastructure. Perfect for companies with custom integration needs or
                                                unique compliance requirements.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-xl-6 col-sm-12'>
                            <div className='app-two-feature-three-img position-relative text-center mt-5 mt-lg-0'>
                                <div className='feature-three-shape'>
                                    <img
                                        src='/assets/img/app-two-feature-blob-shape.png'
                                        alt='app screen'
                                        className='feature-three-blob img-fluid'
                                    />
                                </div>
                                <img
                                    src='/assets/img/app-aws.png'
                                    alt='app screen'
                                    className='position-relative z-5 mw-100'
                                />
                                <div className='img-peice d-none d-lg-block'>
                                    <img
                                        src='/assets/img/app-googlecloud.png'
                                        alt='app screen'
                                        className='img-one position-absolute custom-shadow'
                                    />
                                    <img
                                        src='/assets/img/app-azure.png'
                                        alt='app screfefefefeffffffffffn'
                                        className='img-two position-absolute custom-shadow'
                                    />
                                    {/* <img
                                        src='/assets/img/app-two-chart.png'
                                        alt='app screen'
                                        className='img-three position-absolute custom-shadow'
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImgContentSix;