import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-12 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Why choose us?</h2>
                                <p>
                                    Managing file transfers manually or on a legacy server can be time-consuming, error-prone, and risky. Our service takes the burden off your IT team, providing an
                                    automated, secure, and compliant solution to handle all your file transfer needs. By using our solution, you can reduce operational costs, improve efficiency, and
                                    ensure the safe and timely delivery of files across your organization and beyond.
                                </p>


                            </div>
                        </div>
                        {/* <div className='col-lg-6 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/screen/widget-12.png'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
