import React from 'react';
import PageMeta from '../components/common/PageMeta';

import FeaturesOne from '../components/securefilesharing/featuresone';
import FeaturesTwo from '../components/securefilesharing/featurestwo';


import TrialBanner from "../components/trial/trialbanner";
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';


const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='FilePulse | Managed SFTP File Transfer' />
            <Navbar navDark />
            <HeroEightCustom>
                Secure File Sharing
            </HeroEightCustom>
            <FeaturesOne />
            <FeaturesTwo />
            <TrialBanner />

            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;


