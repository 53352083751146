import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Safe, Efficient, and Simple transfers</h2>
                                <p>
                                    Simplify and automate your file transfer processes with our Managed File Transfer (MFT) solution. Our MFT solution streamlines the transfer of files across platforms,
                                    systems, and networks. Whether you're sending files internally, externally, or between cloud environments.
                                    The application ensures your file transfers are efficient, compliant, and free of manual intervention.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-four.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
