import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="FilePulse SFTP Server | Provides secure file sharing between your teams, partners and cloud infrastructure. | Start your 14-day free trial." />
    </Helmet>
  );
};

export default PageMeta;
