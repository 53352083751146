import React from 'react';
import { Link } from 'react-router-dom';
import BlogPagination from './BlogPagination';

const BlogGrid = () => {
  return (
    <>
      <section className='masonary-blog-section ptb-120'>
        <div className='container'>

          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/post/what-is-mft' className='article-img'>
                  <img
                    src='/assets/img/graphic-people-one.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='/post/what-is-mft'
                      className='d-inline-block text-dark badge bg-warning-soft'
                    >
                      Business
                    </Link>
                  </div>
                  <Link to='/post/what-is-mft'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      What is MFT (Managed File Transfer)?
                    </h2>
                  </Link>


                  <Link to='/post/what-is-mft'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>

                      </div>
                      <div className='avatar-info'>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/post/benefits-cloud-sftp-server' className='article-img'>
                  <img
                    src='/assets/img/graphic-three.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='/post/benefits-cloud-sftp-server'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Business
                    </Link>
                  </div>
                  <Link to='/post/benefits-cloud-sftp-server'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Benefits of using a cloud SFTP server.
                    </h2>
                  </Link>


                  <Link to='/post/benefits-cloud-sftp-server'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>

                      </div>
                      <div className='avatar-info'>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/post/migrating-to-cloud-sftp-server' className='article-img'>
                  <img
                    src='/assets/img/graphic-one.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='/post/migrating-to-cloud-sftp-server'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Development
                    </Link>
                  </div>
                  <Link to='/post/migrating-to-cloud-sftp-server'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      How to migrate to a Cloud SFTP Server
                    </h2>
                  </Link>


                  <Link to='/post/migrating-to-cloud-sftp-server'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>

                      </div>
                      <div className='avatar-info'>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  );
};

export default BlogGrid;
