import React from 'react';
import PageMeta from '../components/common/PageMeta';

import FeaturesOne from '../components/managedsftpserver/featuresone';
import FeaturesTwo from '../components/managedsftpserver/featurestwo';


import TrialBanner from "../components/trial/trialbanner";
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';


const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='FilePulse | Managed SFTP File Transfer' />
            <Navbar navDark />
            <HeroEightCustom>
                Managed SFTP Server
            </HeroEightCustom>
            <FeaturesOne />
            <FeaturesTwo />
            <TrialBanner />

            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;



/*

2) Key Features:




Expert Support: Get  support from our team of experts. Whether you need assistance with setup, troubleshooting, or security configurations, our support team is 
available to ensure your file transfers remain smooth, secure, and efficient.

Integration with Existing Systems: Easily integrate your Managed SFTP Server with existing systems, applications, and cloud storage platforms (like AWS, Azure, 
and Google Cloud). This makes it simple to streamline workflows, automate processes, and securely transfer files between internal and external systems.




*/

