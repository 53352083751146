import React from 'react';

const WorkProcessFour = () => {
    return (
        <>
            <section className='work-process ptb-120'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 col-lg-9'>
                            <div className='section-heading text-center'>
                                <h2>Hybrid Cloud Storage Integration </h2>
                                <p>
                                    Our Storage Gateway facilitates smooth and secure communication between your on-premises applications and leading cloud storage
                                    providers, including AWS, Azure, and Google Cloud. This enables you to retain your local storage infrastructure while leveraging the elasticity, scalability, and
                                    cost-efficiency of the cloud, giving you the best of both worlds in a fully integrated, hybrid environment.


                                </p>
                                <span>
                                    <b>Multi-Protocol Support:</b> Whether you're using FTP, SFTP, HTTP, or other protocols, the application supports a wide range of file and block transfer protocols. This
                                    flexibility makes it easy to integrate the solution with your existing IT systems, ensuring that your storage solution is compatible with the diverse needs of your
                                    business applications, no matter the infrastructure. This enables you to retain your local storage infrastructure while
                                    leveraging the elasticity, scalability, and cost-efficiency of the cloud, giving you the best of both worlds in a fully integrated, hybrid environment.


                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default WorkProcessFour;
