import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const CtaOne = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <section className='bg-dark pb-120'>
                <div className='container'>
                    <div className='pt-100 pb-20 position-relative overflow-hidden rounded-custom'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7 col-md-8'>
                                <div className='subscribe-info-wrap text-center position-relative z-2'>
                                    <SectionTitle
                                        subtitle="Let's Try! Get Free Support"
                                        title='Start Your 14-Day Free Trial'

                                        dark
                                    />
                                    <div className='form-block-banner mw-60 m-auto mt-5'>
                                        <a href="https://app.filepulse.io/auth/register" className='btn btn-primary'>
                                            Get started
                                        </a>

                                    </div>
                                    <ul className='nav justify-content-center subscribe-feature-list mt-4'>
                                        <li className='nav-item'>
                                            <span>
                                                <i className='far fa-check-circle text-primary me-2'></i>
                                                Free 14-day trial
                                            </span>
                                        </li>
                                        <li className='nav-item'>
                                            <span>
                                                <i className='far fa-check-circle text-primary me-2'></i>
                                                No credit card required
                                            </span>
                                        </li>

                                        <li className='nav-item'>
                                            <span>
                                                <i className='far fa-check-circle text-primary me-2'></i>
                                                Cancel anytime
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default CtaOne;
