import React from "react";
import SectionTitle from "../common/SectionTitle";

const FaqOneData = [
    {
        id: 1,
        faqTitle: 'Access Control & Permissions',
        faqDesc:
            'Control who can view, download, or modify your files with customizable access permissions. Grant access to specific users to ensure that only the right people can access your sensitive information.',
    },
    {
        id: 2,
        faqTitle: 'Audit Trails and Activity Logs',
        faqDesc:
            'Keep track of every action taken on shared files with comprehensive audit logs. Whether its file access, modifications, or downloads, our solution provides a detailed history to ensure compliance and enhance accountability across your organization.',
    },
    {
        id: 3,
        faqTitle: 'Large File Support',
        faqDesc:
            'Send files of any size, from large datasets to high-resolution media, without the limitations of traditional email or file-sharing methods. Our platform allows for the seamless transfer of large files without worrying about bandwidth restrictions or file size limits.',
    },
    {
        id: 4,
        faqTitle: 'Cross-Protocol Compatibility',
        faqDesc:
            "Our solution works across multiple devices and protocols, so whether you're using FTP, SFTP, HTTPS, your files are always accessible and securely protected. Collaboration across different systems has never been easier.",
    },
    {
        id: 5,
        faqTitle: 'Seamless Integration',
        faqDesc:
            'Easily integrate our solution with your existing workflows, cloud storage accounts (e.g., AWS S3, Azure, Google Drive), and business applications. This ensures that file sharing becomes a natural extension of your existing infrastructure, without the need for new tools or systems.',
    },
];




const FaqOne = () => {
    return (
        <>
            <section className="faq-section ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <SectionTitle

                                title="Key features"

                                centerAlign
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-12">
                            <div className="faq-wrapper">
                                {FaqOneData.map((data, i) => (
                                    <div className="faq-item mb-5" key={i}>
                                        <h5>
                                            <span className="h3 text-primary me-2">{data.id}.</span>
                                            {data.faqTitle}
                                        </h5>
                                        <p>
                                            {data.faqDesc}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-center mt-4 mt-lg-0 mt-md-0">
                                <img
                                    src='/assets/img/graphic-two.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqOne;
