import React from "react";
import SectionTitle from "../common/SectionTitle";

const FaqOneData = [
    {
        id: 1,
        faqTitle: 'Automated Transfers',
        faqDesc:
            'Control who can view, download, or modify your files with customizable access permissions. Or grant access to specific users to ensure that only the right people can access your sensitive information.',
    },
    {
        id: 2,
        faqTitle: 'End-to-End Security',
        faqDesc:
            'Your data is always secure with our built-in encryption protocols. All file transfers are encrypted ensuring sensitive data is protected.',
    },
    {
        id: 3,
        faqTitle: 'Multi-protocol support',
        faqDesc:
            "Our solution offers the flexibility to tailor file transfer workflows to meet the specific needs of your business. Whether you're transferring files with external or internal partners, our MFT platform can integrate with a wide range of protocols (SFTP, FTPS, HTTPS, and more) to support complex business processes.",
    },
    {
        id: 4,
        faqTitle: 'Scalability and Flexibility',
        faqDesc:
            'As your business grows, so can your file transfer capabilities. Easily scale your file transfer capacity to handle larger file sizes, more frequent transfers, or increased user access without the need for complex upgrades or infrastructure changes.',
    },
];


const FaqOne = () => {
    return (
        <>
            <section className="faq-section ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <SectionTitle

                                title="Key features"

                                centerAlign
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-12">
                            <div className="faq-wrapper">
                                {FaqOneData.map((data, i) => (
                                    <div className="faq-item mb-5" key={i}>
                                        <h5>
                                            <span className="h3 text-primary me-2">{data.id}.</span>
                                            {data.faqTitle}
                                        </h5>
                                        <p>
                                            {data.faqDesc}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-center mt-4 mt-lg-0 mt-md-0">
                                <img src='/assets/img/graphic-two.jpg' alt="faq" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqOne;
