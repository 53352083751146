import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-9 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Managed Version – Hassle-Free, Fully Supported Solution</h2>
                                <p>
                                    For businesses that want a turnkey solution with minimal overhead, our Managed Storage Gateway offers the perfect option. Delivered as a SaaS (Software-as-a-Service)
                                    solution, this version allows you to outsource all the complexity of deployment, configuration, management, and maintenance to us. We take care of everything—from setting
                                    up your storage gateway to regular updates, monitoring, security patches, and backups—ensuring your system runs smoothly and securely without requiring internal resources.

                                </p>

                                <p>
                                    With the Managed Version, you benefit from expert support, proactive monitoring, and built-in scalability. As your storage needs grow, we scale the infrastructure on
                                    your behalf to handle larger volumes of data or increased traffic, ensuring optimal performance at all times. This fully managed service guarantees high availability,
                                    minimizing the risk of downtime, and providing reliable performance backed by industry-leading cloud providers.

                                </p>

                                <p>
                                    For businesses seeking a low-maintenance solution, thef managed version ensures you can focus on what matters most—your core business—without worrying about the technical
                                    details of managing storage infrastructure. With this service, you also gain access to regular updates and new features that are deployed automatically, keeping you ahead
                                    of the curve and maintaining compliance with the latest security standards.

                                </p>


                            </div>
                        </div>
                        <div className='col-lg-3 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-five.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
