import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureOne = () => {
    return (
        <>
            <section className='feature-promo ptb-120 bg-light'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10 col-md-10'>
                            <SectionTitle
                                title='Flexible Deployment Options – Tailored to Your Business Needs'
                                description="We understand that every organization has unique requirements when it comes to managing and deploying storage solutions. Whether you’re looking for a fully managed, hassle-free service or prefer more control over your infrastructure, our Storage Gateway is designed to offer maximum flexibility. That's why we provide both managed and self-hosted versions, ensuring you can choose the deployment model that aligns best with your business goals, IT capabilities, and operational preferences.
                            "
                                centerAlign
                            />
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default FeatureOne;
