import React from 'react';
import FeatureOne from '../components/features/FeatureOne';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import HeroEight from './HeroEight';
import CtaFive from '../components/cta/CtaFive';

const FeatureApp = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Managed SFTP File Transfer' />
      <Navbar navDark />
      <HeroEight />


      <FeatureOne />


      <CtaFive />

      <FooterOne footerLight />
    </Layout>
  );
};

export default FeatureApp;
