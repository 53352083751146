import React from 'react';
import HeroTitle from './../components/common/HeroTitle';

const HeroSix = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          background:
            "url('/assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                data-aos='fade-right'
              >
                <HeroTitle
                  title='Fully managed Cloud SFTP Server'
                  desc='Combine the scalability of your cloud filestorage service with the flexibility of a SFTP server. All accessible through an easy-to-use interface.'
                />
                <div className='hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block'>

                  <ul className='nav subscribe-feature-list mt-3 justify-content-lg-start justify-content-md-center'>
                    <li className='nav-item'>
                      <span className='ms-0'>
                        <i className='far fa-check-circle text-primary me-2'></i>
                        Free 14-day trial
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-primary me-2'></i>
                        No credit card required
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 mt-5'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-screen-wrap'>
                  <div className='phone-screen'>

                  </div>
                  <div className='mac-screen'>
                    <img
                      src='/assets/images/mac-screen.png'
                      alt='app screen'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSix;

