import React from "react";
import { Link } from "react-router-dom";

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src="/assets/img/filepulse-dark.png"
              alt="logo"
              className="img-fluid ps-2"
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Solutions
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-full">

                  <div className="dropdown-grid-item radius-right-side bg-light">


                    <Link to="/solutions/cloud-storage-gateway" className="dropdown-link">
                      Cloud storage gateway
                    </Link>
                    <Link to="/solutions/managed-sftp-server" className="dropdown-link">
                      Managed (S)FTP Server

                    </Link>
                    <Link to="/solutions/secure-file-sharing" className="dropdown-link">

                      Secure file sharing
                    </Link>
                    <Link to="/solutions/self-hosted-data-transfer-solution" className="dropdown-link">
                      Self-Hosted Data Transfer Solution
                    </Link>


                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-full">

                  <div className="dropdown-grid-item radius-right-side bg-light">


                    <Link to="/products/cloud-sftp-server" className="dropdown-link">
                      Cloud (S)FTP Server
                    </Link>
                    <Link to="/products/managed-file-transfer" className="dropdown-link">
                      Managed File Transfer
                    </Link>
                    <Link to="/products/storage-gateway" className="dropdown-link">
                      Storage gateway
                    </Link>

                  </div>
                </div>
              </div>
            </li>

            <li>
              <Link to="/pricing" className="nav-link">
                Pricing
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Resources
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-full">

                  <div className="dropdown-grid-item radius-right-side bg-light">
                    <Link to="/faq" className="dropdown-link">

                      <div className="drop-title">FAQ</div>
                    </Link>
                    <Link to="/blog" className="dropdown-link">

                      <div className="drop-title">Blog</div>
                    </Link>

                  </div>
                </div>
              </div>
            </li>

            <li>
              <a href="https://app.filepulse.io/auth/login" className="nav-link">
                Login
              </a>
            </li>

            <li>
              <a href="https://documentation.filepulse.io/" className="nav-link">
                Documentation
              </a>
            </li>
          </ul>
          <div className="action-btns mt-4 ps-3">

            <Link to="/request-for-demo" className="btn btn-primary">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
