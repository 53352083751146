import React from "react";
import { Link } from 'react-router-dom';

const PriceThree = () => {
  return (
    <section className="price-two ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h2>
                Choose Your SAAS Pricing Plan
              </h2>
              <p>
                Not sure which plan is right for you? We can help you find the perfect plan for your business.
                You can upgrade or downgrade at any time.


              </p>
              <p>
                Planning on bringing your own cloud storage? Pick our BYOS plans and save up to 50% on your monthly bill.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Small</h3>
                <h4 className="display-6 fw-semi-bold">
                  $25<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    5 Users included
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    25 GB of storage
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    150 GB of bandwidth
                  </li>

                </ul>
              </div>
              <a
                href="https://app.filepulse.io/#/auth/register"
                className="btn btn-outline-primary mt-2"
              >
                Get Started Now
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-dark text-white p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-warning d-block">Medium (Most popular)</h3>
                <h4 className="display-6 fw-semi-bold">
                  $49<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    15 Users included
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    100 GB of storage
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    300 GB of bandwidth
                  </li>

                </ul>
              </div>
              <a href="https://app.filepulse.io/#/auth/register" className="btn btn-primary mt-2">
                Get Started Now
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Large</h3>
                <h4 className="display-6 fw-semi-bold">
                  $99<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    30 Users included
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    300 GB of storage
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1 TB of bandwidth
                  </li>

                </ul>
              </div>
              <a
                href="https://app.filepulse.io/#/auth/register"
                className="btn btn-outline-primary mt-2"
              >
                Get Started Now
              </a>
            </div>
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Extra large</h3>
                <h4 className="display-6 fw-semi-bold">
                  $150<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    50 Users included
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1000 GB of storage
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    2 TB of bandwidth
                  </li>

                </ul>
              </div>
              <a
                href="https://app.filepulse.io/#/auth/register"
                className="btn btn-outline-primary mt-2"
              >
                Get Started Now
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Enterprise</h3>
                <h4 className="display-6 fw-semi-bold">
                  <span>Starts at</span> $500<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited users
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Custom storage
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Custom bandwidth
                  </li>

                </ul>
              </div>
              <a
                href="https://app.filepulse.io/#/auth/register"
                className="btn btn-outline-primary mt-2"
              >
                Contact us
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Self managed</h3>
                <h4 className="display-6 fw-semi-bold">
                  <span>Starts at </span>$30<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited users
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Usage based pricing for bandwidth
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Usage based pricing for storage
                  </li>
                  {/* <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    150 GB of bandwidth
                  </li> */}

                </ul>
              </div>
              <a
                href="https://app.filepulse.io/#/auth/register"
                className="btn btn-outline-primary mt-2"
              >
                Get Started Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h2>
                BYOS (Bring Your Own Storage)
              </h2>
              <p>
                Planning on linking your own cloud storage account (S3, Azure Blob, Google Cloud Storage)?



              </p>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Small (BYOS)</h3>
                <h4 className="display-6 fw-semi-bold">
                  $15<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    5 Users included
                  </li>

                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    150 GB of bandwidth
                  </li>
                  <li>
                    <i className="far fa-cross-circle text-negative me-2"></i>
                    No storage included (BYOS)
                  </li>
                </ul>
              </div>
              <a href="/contact" className="btn btn-primary mt-2">
                Contact us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-warning d-block">Medium (BYOS)</h3>
                <h4 className="display-6 fw-semi-bold">
                  $29<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    15 Users included
                  </li>

                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    300 GB of bandwidth
                  </li>
                  <li>
                    <i className="far fa-cross-circle text-negative me-2"></i>
                    No storage included (BYOS)
                  </li>
                </ul>
              </div>
              <a href="/contact" className="btn btn-primary mt-2">
                Contact us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Large</h3>
                <h4 className="display-6 fw-semi-bold">
                  $59<span>/month</span>
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    30 Users included
                  </li>

                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1 TB of bandwidth
                  </li>
                  <li>
                    <i className="far fa-cross-circle text-negative me-2"></i>
                    No storage included (BYOS)
                  </li>
                </ul>
              </div>
              <a href="/contact" className="btn btn-primary mt-2">
                Contact us
              </a>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default PriceThree;
