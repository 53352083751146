import React from 'react';

import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import HeroEightCustom from './HeroEightCustom';




const BlogDetails = () => {
    return (
        <>
            <Layout>
                <PageMeta title='FilePulse | Managed SFTP File Transfer' />
                <Navbar navDark />
                <HeroEightCustom>
                    What is MFT (Managed File Transfer)
                </HeroEightCustom>

                <section className='blog-details ptb-120'>
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-9 pe-5'>
                                <div className='blog-details-wrap'>
                                    <h3 className='h5'>What is MFT (Managed file transfer)</h3>
                                    <p>
                                        Managed File Transfer (MFT) is designed to securely transfer files between
                                        systems, applications, and people, both within an organization and externally. MFT provides a centralized
                                        approach to managing all aspects of file transfers, from secure encryption and authentication to auditing
                                        and compliance.
                                    </p>
                                    <div className='blog-details-info mt-5'>
                                        <h3 className='h5'>Key benefits of managed file transfer.</h3>
                                        <ul className='content-list list-unstyled'>
                                            <li>
                                                <b>Data security</b> One of the primary benefits of Managed File Transfer (MFT) is its robust data security features,
                                                which ensure that sensitive files are transferred safely and securely. Additionally, the FilePulse MFT
                                                platform includes features like user access controls and detailed audit logs.
                                            </li>
                                            <li>
                                                <b>Scaling</b> As businesses grow and their data transfer needs expand, scalability becomes a critical factor in ensuring
                                                operations continue smoothly. Our solutions are designed to scale effortlessly with your organization’s needs,
                                                handling increased file volumes and users.
                                            </li>
                                            <li>
                                                <b>Adaptable </b>
                                                MFT solutions support a broad range of file transfer protocols, such as SFTP, FTPS, HTTPs, AS2, ... Moreover,
                                                the systems are flexible enough to integrate new protocols and ever-changing technology landscapes.
                                                Additionally, MFT platforms offer seamless integration capabilities, connecting with various systems like ERP, CRM, cloud services, and data storage platforms.

                                            </li>

                                        </ul>

                                    </div>
                                    <img
                                        src='/assets/img/graphic-one.jpg'
                                        alt='article'
                                        className='img-fluid'
                                        width="400"
                                    />
                                    <div className='job-details-info mt-5'>
                                        <h3 className='h5'>
                                            Migrating to a MFT solution
                                        </h3>
                                        <p>
                                            Migrating to a Managed File Transfer (MFT) solution can help deliver a more efficient file transfer process
                                            and save both costs and resources in the process.
                                            Our MFT solution is designed to integrate seamlessly with existing infrastructure, allowing businesses
                                            to gradually transition from legacy transfer methods (Email, SFTP).
                                        </p>

                                    </div>




                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <FooterOne footerLight />
            </Layout>
        </>
    );
};

export default BlogDetails;
