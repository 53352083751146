import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-8 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Cloud SFTP Server</h2>
                                <p>
                                    Easily manage your file transfers with the power of the cloud. The FilePulse SFTP Server solution delivers the security, reliability, and scalability your
                                    business needs to streamline file sharing and collaboration across teams and partners. With an enterprise-grade SFTP server
                                    hosted in the cloud, you can securely exchange files of any size without the complexities and costs of on-premise infrastructure.

                                </p>

                                <h4>Key benefits</h4>
                                <p>
                                    <b>No maintenance </b>
                                    Our fully managed solution handles all the maintenance and upgrades. Ensuring your server is up-to-date, secure and running smoothly.
                                </p><p>
                                    <b>Scalability </b>
                                    We allow you to easily adjust storage and bandwith as your business grows. Unlike traditional on-premise solutions, you can expand your capacity on-demand without the need
                                    for costly hadware upgrades or downtime.
                                </p><p>
                                    <b>User-friendly management </b>
                                    Manage users, permissions and storage services all through an intiuitive dashboard. No need for complex configuration files or technical knowledge -just simple, effective administrator

                                </p>


                            </div>
                        </div>
                        <div className='col-lg-4 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-three.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
