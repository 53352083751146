import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Storage gateway</h2>
                                <p>
                                    Bridge the gap between your on-premises infrastructure and cloud storage with our advanced Storage Gateway solution. Whether you're looking to extend your existing
                                    data center to the cloud, move large volumes of data seamlessly, or hybridize your storage architecture, our Storage Gateway provides a smooth, secure, and efficient
                                    way to manage data across multiple environments.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-3 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-people-one.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
