import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='/assets/img/filepulse-dark.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='/assets/img/filepulse-dark.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>




                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Products</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/products/cloud-sftp-server'
                            className='text-decoration-none'
                          >
                            Cloud SFTP Server
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/products/managed-file-transfer'
                            className='text-decoration-none'
                          >
                            Managed File Transfer
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/products/storage-gateway'
                            className='text-decoration-none'
                          >
                            Storage gateway
                          </Link>
                        </li>
                      </ul>
                      <p></p>
                      <h3 className='mt-md-1'>Posts</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/post/what-is-mft'
                            className='text-decoration-none'
                          >
                            What is MFT (Managed File Transfer)
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/post/migrating-to-cloud-sftp-server'
                            className='text-decoration-none'
                          >
                            Migrating to a cloud SFTP Server
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/post/benefits-cloud-sftp-server'
                            className='text-decoration-none'
                          >
                            Benefits of a cloud based SFTP Server
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Solutions</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/solutions/cloud-storage-gateway'
                            className='text-decoration-none'
                          >
                            Cloud Storage Gateway
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/solutions/managed-sftp-server'
                            className='text-decoration-none'
                          >
                            Managed SFTP Server
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/solutions/secure-file-sharing'
                            className='text-decoration-none'
                          >
                            Secure File Sharing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/solutions/self-hosted-data-transfer-solution'
                            className='text-decoration-none'
                          >
                            On-premise SFTP Server
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Pages</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/'
                            className='text-decoration-none'
                          >
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/features'
                            className='text-decoration-none'
                          >
                            Features
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/pricing'
                            className='text-decoration-none'
                          >
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/blog'
                            className='text-decoration-none'
                          >
                            Blog
                          </Link>
                        </li>

                        <li>
                          <a href="https://documentation.filepulse.io" >
                            Documentation
                          </a>
                        </li>
                        {/* <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            About
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to='/contact'
                            className='text-decoration-none'
                          >
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/terms-of-service'
                            className='text-decoration-none'
                          >
                            Terms of Service
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </footer>
    </>
  );
};

export default FooterOne;
