import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const img = () => {
    if (scroll > headerTop) {
      return "/assets/img/filepulse-dark.png"

    } else {
      return "/assets/img/filepulse.png"
    }
  }

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${navDark ? "navbar-dark" : "navbar-light"
            } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img src={img()} alt="" height="60" />
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Solutions
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-half">

                      <div className="dropdown-grid-item rounded-custom bg-light">
                        <h5 className="drop-heading">Solutions</h5>

                        <Link to="/solutions/cloud-storage-gateway" className="dropdown-item">
                          Cloud storage gateway
                        </Link>
                        <Link to="/solutions/managed-sftp-server" className="dropdown-item">
                          Managed (S)FTP Server

                        </Link>
                        <Link to="/solutions/secure-file-sharing" className="dropdown-item">

                          Secure file sharing
                        </Link>
                        <Link to="/solutions/self-hosted-data-transfer-solution" className="dropdown-item">
                          Self-Hosted Data Transfer Solution
                        </Link>


                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-full">
                      <div className="dropdown-grid-item">
                        <h6 className="drop-heading">Products</h6>
                        <Link to="/products/cloud-sftp-server" className="dropdown-item">
                          Cloud (S)FTP Server
                        </Link>
                        <Link to="/products/managed-file-transfer" className="dropdown-item">
                          Managed File Transfer
                        </Link>
                        <Link to="/products/storage-gateway" className="dropdown-item">
                          Storage gateway
                        </Link>

                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-light">
                        <h6 className="drop-heading">Features</h6>
                        <Link to="/solutions/secure-file-sharing" className="dropdown-item ">
                          Advanced Security and Compliance
                        </Link>
                        <Link to="/pricing" className="dropdown-item">
                          Customisable Storage and Bandwidth Plans
                        </Link>
                        <Link to="/features#usermanagement" className="dropdown-item">
                          User management
                        </Link>
                        <Link to="/features" className="dropdown-item" >
                          <u>See all</u>
                        </Link>

                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/pricing" className="nav-link">
                    Pricing
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Resources
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom">
                      <div className="dropdown-grid-item">
                        <h6 className="drop-heading">Useful Links</h6>
                        <Link to="/faq" className="dropdown-item">
                          FAQ
                        </Link>
                        <a href="https://documentation.filepulse.io/" className="dropdown-item">
                          Documentation
                        </a>


                        <Link to="/blog" className="dropdown-item">
                          Blog
                        </Link>
                        <Link to="/contact" className="dropdown-item">
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <a href="https://app.filepulse.io/auth/login" className="nav-link">
                    Login
                  </a>
                </li>
              </ul>
            </div>


            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">

              <a href="https://app.filepulse.io/auth/register" className="btn btn-primary">
                Get started
              </a>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
