import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Safe, Efficient, and Simple Collaboration</h2>
                                <p>

                                    Easily and securely share files with complete confidence. The platform safeguards your sensitive information while enabling smooth collaboration. Whether sharing documents within your organization or externally, our solution offers a fully encrypted environment, ensuring safe file transfers of any size without compromising security.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-one.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
