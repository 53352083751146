import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
  return (
    <>
      <section className='faq-section ptb-120 bg-light'>
        <div className='container'>


          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <SectionTitle subtitle='FAQ' title='Frequently Asked Questions' description="Can't find an answer to your question? Feel free to reach out at hello@filepulse.io" centerAlign />

            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12 mb-4 mt-5'>
              <h5 class="center-align">
                Solutions
              </h5>


            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      What types of files can I transfer using your service?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      You can transfer a wide range of file types using our service, including documents, images, videos, and more. Our platform supports large files, making it easy to transfer data of various sizes without issues.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      How does your SFTP solution improve file transfer efficiency?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Our SFTP solution optimizes file transfer efficiency through features like resumable uploads, bandwidth throttling, and parallel transfers. This means you can transfer files faster and more reliably, even with larger datasets.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Is your solution scalable for businesses of all sizes?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Yes, our solution is designed to scale with your business. Whether you’re a small startup or a large enterprise, our platform can accommodate your growing needs, allowing you to easily adjust your storage and user access as required.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      Can I integrate your SFTP service with my existing systems?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Absolutely! Our SFTP solution offers integration capabilities with various applications and systems, allowing you to streamline your workflows and enhance productivity. We provide APIs and documentation to assist with the integration process.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      How difficult is it to maintain the solution?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Maintaining our cloud SFTP solution is designed to be user-friendly and straightforward. We handle the backend infrastructure, security updates, and system maintenance, allowing you to focus on using the service without worrying about technical complexities. Our intuitive interface makes it easy to manage file transfers and user access, and our support team is always available to assist you with any questions or issues that may arise. Overall, you can expect a low-maintenance experience with our solution.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12 mb-4 mt-5'>
              <h5 class="center-align">
                Pricing
              </h5>


            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      Is there a setup fee?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      No, we don’t have a setup fee! You can choose a plan that suits your needs and start using our service immediately, without any additional costs. Enjoy a hassle-free experience from the get-go!
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      What happens when I hit a limit?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      When you reach a soft limit on your plan, you will receive a notification informing you that you've hit the limit. Rest assured, your service will continue uninterrupted until you reach double the limit of your chosen plan. This gives you the flexibility to manage your usage without any immediate disruptions.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      How can I switch plans?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Switching plans is easy! Simply log into your account and navigate to the billing or subscription section. From there, you can select your desired plan and follow the prompts to complete the switch. If you need any assistance, feel free to reach out to our support team!
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      My usage doesn't fit in one of the advertised plans. Do you offer custom plans?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Yes, we understand that everyone’s needs are different! If our advertised plans don’t quite fit your usage, we can work with you to create a custom plan that meets your specific requirements. Please contact our support team to discuss your needs, and we’ll be happy to assist you!
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      What happens if my payment fails, like if my credit card expires?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      If your payment fails due to an expired credit card or any other reason, you will receive a notification from us. Your service will remain active for a grace period to allow you time to update your payment information. After the grace period, if the payment issue isn’t resolved, your service may be temporarily suspended. To avoid any interruptions, please make sure to keep your payment details up to date!
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      How are my payments being processed?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      All payments are processed securely through Stripe, a trusted payment processing platform. Stripe uses advanced encryption and security measures to ensure that your payment information is protected. You can rest assured that your transactions are handled safely and efficiently.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      What payment methods does Stripe accept?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Stripe accepts a variety of payment methods to make transactions convenient for you. This includes major credit and debit cards (such as Visa, MasterCard, American Express, and Discover), as well as digital wallets like Apple Pay and Google Pay. For specific payment options available in your region, please check during the checkout process.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      What happens at the end of the free trial?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      At the end of your free trial, your account will automatically transition to a paid plan based on the selection you made when signing up. You’ll receive a notification before the trial ends, reminding you of the upcoming charge. If you decide not to continue, you can cancel your subscription at any time during the trial to avoid any charges.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12 mb-4 mt-5'>
              <h5 class="center-align">
                Account
              </h5>


            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      Do you offer a free trial?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Yes, we offer a free 14 day trial so you can explore our services and see if they meet your needs! During the trial period, you’ll have access to all the features of your chosen plan. You can sign up on our website, and if you decide to continue, your account will automatically transition to a paid plan at the end of the trial.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      I didn't receive my registration email?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      If you didn’t receive your registration email, please check your spam or junk folder first, as it may have been filtered there. If you still can’t find it, you can request a new registration email by clicking the "Resend Email" link on the login page. If you continue to have issues, please contact our support team for further assistance.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      How can I close my account
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      To close your account, please log into your account settings and navigate to the account management section. There, you’ll find the option to close your account. If you need assistance or have any questions, feel free to reach out to our support team, and we’ll be happy to help!
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What happens to my data when I close my account?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      When you close your account, all associated data will be permanently deleted from our systems, including files, settings, and any personal information. If you want to keep any data, please make sure to back it up before closing your account. If you have any questions or need assistance, feel free to reach out to our support team.
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12 mb-4 mt-5'>
              <h5 class="center-align">
                Security
              </h5>


            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      How is my data protected during transfer?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Your data is protected during transfer using strong encryption protocols, such as SFTP (SSH File Transfer Protocol). This ensures that your files are securely transmitted over the internet, preventing unauthorized access and maintaining the confidentiality of your data.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      What security measures are in place to protect my stored files?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      We implement multiple layers of security to protect your stored files, including data encryption at rest, secure access controls, and regular security audits. Additionally, our infrastructure is built on reputable cloud providers that comply with industry standards for data security.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Can I control who has access to my files?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Yes, you have full control over who can access your files. Our platform allows you to set user permissions and roles, ensuring that only authorized individuals can view or manage specific files or directories.


                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What happens in the event of a data breach?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      In the unlikely event of a data breach, we have an incident response plan in place to quickly address the situation. We will notify affected users promptly, investigate the breach, and take necessary measures to secure the system and prevent future incidents.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default FaqTwo;
