import React from 'react';
import { Link } from 'react-router-dom';

const HeroEightCustom = ({ children }) => {
    return (
        <>
            <section
                className='bg-dark pt-120 pb-6e0 text-white'

            >
                <div className='container'>
                    <div className='row justify-content-xl-between align-items-center'>
                        <div className='col-lg-9 col-xl-9 col-xl-9 col-md-10'>
                            <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                                <h1 className='fw-bold display-5'>
                                    {children}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroEightCustom;