import React from "react";
import SectionTitle from "../common/SectionTitle";

const FaqOneData = [
    {
        id: 1,
        faqTitle: 'Fully Managed Service',
        faqDesc: ' Enjoy peace of mind with our completely managed SFTP server solution. We handle everything from server setup and maintenance to security updates and backups, ensuring your system is always running smoothly and securely without the need for your internal IT team to get involved.',
    },
    {
        id: 2,
        faqTitle: 'End-to-End Encryption',
        faqDesc:
            'Protect your data with industry-leading encryption standards. Our SFTP server uses strong encryption (TLS/SSL) for secure communication and AES encryption for data at rest, keeping your files safe throughout the transfer process and while stored on the server.',
    },
    {
        id: 1,
        faqTitle: 'Compliance and Audit Trails',
        faqDesc:
            'Stay compliant with industry regulations. Our Managed SFTP Server includes built-in audit trails, detailed access logs, and file transfer  tracking, so you can maintain full visibility of every transaction and ensure your file transfers are secure and compliant.',
    },
    {
        id: 1,
        faqTitle: 'User Access Control',
        faqDesc:
            'Simplify user management and security with customizable access controls. You can define user roles, permissions, and access levels for  each individual or team, ensuring that sensitive files are only accessible by the right people and that security policies are enforced consistently.',
    },
    {
        id: 1,
        faqTitle: 'Automated File Transfers',
        faqDesc:
            'Automate your file transfers with scheduling and scripting features, eliminating the need for manual intervention. Whether you need to send files daily, weekly, or on-demand, our Managed SFTP Server handles it automatically, ensuring timely and reliable transfers every time.',
    },
    {
        id: 1,
        faqTitle: 'High Availability & Scalability',
        faqDesc:
            'Our fully managed service is built for high availability, with automatic failover, load balancing, and redundant systems to ensure  your SFTP server is always online. Whether you’re scaling up for more users or increasing the volume of data you transfer, our infrastructure can grow with your business needs without disruption.',
    },
    {
        id: 1,
        faqTitle: 'Expert Support',
        faqDesc:
            'Get support from our team of experts. Whether you need assistance with setup, troubleshooting, or security configurations, our support team is available to ensure your file transfers remain smooth, secure, and efficient.',
    },
    {
        id: 1,
        faqTitle: 'Integration with Existing Systems',
        faqDesc:
            'Easily integrate your Managed SFTP Server with existing systems, applications, and cloud storage platforms (like AWS, Azure,  and Google Cloud). This makes it simple to streamline workflows, automate processes, and securely transfer files between internal and external systems.',
    }
];


const FaqOne = () => {
    return (
        <>
            <section className="faq-section ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <SectionTitle

                                title="Key features"

                                centerAlign
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-12">
                            <div className="faq-wrapper">
                                {FaqOneData.map((data, i) => (
                                    <div className="faq-item mb-5" key={i}>
                                        <h5>
                                            {data.faqTitle}
                                        </h5>
                                        <p>
                                            {data.faqDesc}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo integration-logo-google col-5">
                                    <img
                                        src='/assets/img/app-googlecloud.png'
                                        alt='app screeffffn'
                                        className="integration-logo-img"
                                    />
                                </div>

                            </div>
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo col-5">
                                    <img
                                        src='/assets/img/app-azure.png'
                                        alt='app screeffffn'
                                        className="integration-logo-img"
                                    />
                                </div>

                            </div>
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo integration-logo-aws col-5">
                                    <img
                                        src='/assets/img/app-aws.png'
                                        alt='app screeffffn'
                                        className=""
                                    />
                                </div>

                            </div>
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo integration-logo-googledrive col-5">
                                    <img
                                        src='/assets/img/app-google-drive.png'
                                        alt='app screeffffn'
                                        className="integration-logo-img"

                                    />
                                </div>

                            </div>
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo integration-logo-onedrive col-5">
                                    <img
                                        src='/assets/img/app-onedrive.png'
                                        alt='app screeffffn'
                                        className="integration-logo-img"
                                    />
                                </div>

                            </div>
                            <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                <div className="integration-logo integration-logo-sharepoint col-5">
                                    <img
                                        src='/assets/img/app-sharepoint.png'
                                        alt='app screeffffn'
                                        className="integration-logo-img"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqOne;
