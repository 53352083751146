import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-8 col-8'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Security features</h2>
                                <p>

                                    Our Cloud SFTP Server provides robust access controls, allowing you to define granular permissions for users and teams. You can set specific roles and restrict access
                                    to certain files or directories, ensuring that only authorized individuals can interact with sensitive data.
                                </p>

                                <h4>Full audit log</h4>
                                <p>
                                    With our comprehensive audit logging feature, you can track every file transfer, user activity, and system interaction in real-time. Detailed logs provide full visibility
                                    into actions taken on your server, helping you maintain compliance and monitor for any unusual behavior.
                                </p>

                                <h4>Encrypted file transfers</h4>
                                <p>
                                    All file transfers are protected by end-to-end encryption, ensuring that your data remains secure both in transit and at rest. Whether you're sending sensitive documents
                                    across the globe or syncing files internally, our advanced encryption protocols safeguard your files against unauthorized access.

                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
