import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-8 col-12 order-lg-0'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>BYOS (Bring your own storage)</h2>
                                <p>


                                    The solution empowers you to integrate your existing document storage services, such as Azure, S3, and SharePoint, seamlessly. This ensures smooth compatibility with your current infrastructure, giving you full control over where and how your data is stored or backed up while minimizing additional costs. With effortless integration and complete compatibility, you can scale and optimize your file transfer processes, keeping everything centralized for greater efficiency.
                                </p>

                            </div>
                        </div>
                        <div className='col-lg-4 col-12 order-lg-1'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-people-one.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
