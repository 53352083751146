import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                    <i className='fal fa-fingerprint fa-2x text-white'></i>
                                </div>
                                <h2>Storage transfer</h2>
                                <p>
                                    Unlock the full potential of hybrid cloud storage with our advanced Storage Gateway solution. Whether you need to extend your on-premises data center to the cloud,
                                    move large volumes of data effortlessly, or create a hybrid storage environment, our Storage Gateway offers a seamless, secure, and efficient way to integrate and
                                    manage data across multiple infrastructures. With this solution, you can enjoy the flexibility of cloud storage while maintaining the performance and control of your
                                    local systems.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <img
                                    src='/assets/img/graphic-four.jpg'
                                    className='img-fluid'
                                    alt='feature-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
