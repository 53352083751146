import React from 'react';
import { Link } from 'react-router-dom';
import Rating from './../review/Rating';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-1'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Managed SFTP Server – Secure, Hassle-Free File Transfers with Expert Support</h2>
                                <p>
                                    Simplify and secure your file transfers with our fully managed SFTP server solution. Our Managed SFTP Server allows businesses to securely exchange files over
                                    the internet without the complexities of managing infrastructure, software updates, or security protocols. Whether you’re exchanging files with partners, clients,
                                    or teams, our managed service ensures high-level security, compliance, and reliability—so you can focus on your business, not your file transfer infrastructure.

                                </p>


                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-0'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                                    <img
                                        src='/assets/img/testimonial/quotes-left.svg'
                                        alt='testimonial quote'
                                        width='65'
                                        className='img-fluid mb-32'
                                    />
                                    <div className='blockquote-title-review mb-4'>

                                        <Rating />
                                    </div>
                                    <blockquote className='blockquote'>
                                        Globally network long-term high-impact schemas
                                        vis-a-vis distinctive e-commerce cross-media
                                        infrastructures rather than ethical sticky
                                        alignments rather than global. Plagiarize
                                        technically sound total linkage for leveraged
                                        value media web-readiness and premium processes.
                                    </blockquote>
                                    <div className='author-info mt-4'>
                                        <h6 className='mb-0'>Tim, R.</h6>
                                        <span>System administrator</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
