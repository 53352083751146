import React from 'react';
import { Link } from 'react-router-dom';

const FeatureImg = () => {
    return (
        <>
            <section className='image-feature pt-60 pb-120'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-5 col-12 order-lg-0'>
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                </div>
                                <h2>Key features</h2>
                                <p>
                                    Share files securely, effortlessly, and with confidence. Our Secure File Sharing solution ensures that your sensitive data is protected while enabling seamless
                                    collaboration across teams and clients. Whether you’re exchanging documents internally or externally, our platform provides a fully encrypted environment,
                                    allowing you to transfer files of any size without compromising security.
                                </p>


                                <p>
                                    We understand that every business has unique needs when it comes to deploying storage solutions. That’s why we offer both managed and self-hosted versions of our Storage
                                    Gateway, giving you the flexibility to choose the deployment model that best fits your infrastructure and business requirements.

                                </p>

                                <h4>Managed</h4>
                                <p>For businesses that want a fully supported, hassle-free experience, our managed Storage Gateway is available as a SASS solution.
                                    Enjoy a fully managed service with expert support, built-in scalability, and complete reliability, backed by our expert team.
                                </p>
                                <h4>On-premise</h4>
                                <p>
                                    For organizations that require more control over their environment, our self-hosted Storage Gateway is also available through these same marketplaces. You can deploy it within your own cloud account and manage the configuration, scaling, and maintenance yourself. This option provides greater flexibility for custom configurations and integrations with your existing infrastructure while still benefiting from the cloud's performance and scalability.
                                </p>


                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-1'>
                            <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo integration-logo-google col-5">
                                        <img
                                            src='/assets/img/app-googlecloud.png'
                                            alt='app screeffffn'
                                            className="integration-logo-img"
                                        />
                                    </div>

                                </div>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo col-5">
                                        <img
                                            src='/assets/img/app-azure.png'
                                            alt='app screeffffn'
                                            className="integration-logo-img"
                                        />
                                    </div>

                                </div>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo integration-logo-aws col-5">
                                        <img
                                            src='/assets/img/app-aws.png'
                                            alt='app screeffffn'
                                            className=""
                                        />
                                    </div>

                                </div>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo integration-logo-googledrive col-5">
                                        <img
                                            src='/assets/img/app-google-drive.png'
                                            alt='app screeffffn'
                                            className="integration-logo-img"

                                        />
                                    </div>

                                </div>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo integration-logo-onedrive col-5">
                                        <img
                                            src='/assets/img/app-onedrive.png'
                                            alt='app screeffffn'
                                            className="integration-logo-img"
                                        />
                                    </div>

                                </div>
                                <div className="text-center mt-4 mt-lg-0  mt-md-0">
                                    <div className="integration-logo integration-logo-sharepoint col-5">
                                        <img
                                            src='/assets/img/app-sharepoint.png'
                                            alt='app screeffffn'
                                            className="integration-logo-img"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
